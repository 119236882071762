<template>
  <HomePortfolioPropertiesSlider
    v-if="orlandoIds && orlandoIds.length"
    :ids="orlandoIds"
  />
</template>

<script setup lang="ts">
import { GET_WP_FEATURED_PROJECTS_ORLANDO } from '@/utils/queries';

const HomePortfolioPropertiesSlider = defineAsyncComponent(
  () => import('@/async-components/Home/PortfolioPropertiesSlider.vue')
);

const { language } = useLanguage();

const client = useWpClient();

async function fetch() {
  const gqlResponse = await client.query<{
    home: {
      fields: {
        orlandoProjects: {
          title: string;
          databaseId: string;
        }[];
      };
    };
  }>({
    query: GET_WP_FEATURED_PROJECTS_ORLANDO,
    variables: {
      language: language.value
    }
  });

  const ids = gqlResponse.data.home.fields.orlandoProjects.map(project =>
    project.databaseId.toString()
  );

  return ids;
}

const { data: orlandoIds } = await useServerAsyncData(
  'orlandoIds',
  fetch,
  (error: Error) => {
    console.error(error);
  }
);
</script>
